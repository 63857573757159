.Intro {
    max-width: var(--max-width);
}

.center {
    justify-content: center;
}

.left {
    text-align: center;
}

.Intro h3 {
    font-size: 1.75rem;
    margin: .3rem 0;
    font-weight: 500;
}

.badges {
    display: inline-block;
    margin: .3rem 0;
}

.badges a {
    margin: 0 .2rem;
}

.introduction {
    align-self: center;
    font-weight: lighter;
    font-size: larger;
    text-align: left;
}

.row>div {
    max-width: 526px;
}