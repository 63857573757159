.MyNavbar {
    background-color: #ffffff7a;
}
/* .Navbar {
    display: flex;
    flex-flow: row nowrap;
    gap: 2rem;
}

.Navbar div {
    padding: .5rem 1.2rem;
}

.Navbar div[custom-attribute="disabled"] {
    color: gray;
}

.Navbar div[custom-attribute="disabled"]:hover {
    cursor: not-allowed;
}

.Navbar div:not([custom-attribute="disabled"]):hover {
    cursor: pointer;
} */