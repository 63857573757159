.profilePic {
    width: var(--prof-pic-size);
    height: var(--prof-pic-size);
    border: .5rem solid rgb(255, 255, 255);
    border-radius: 50%;
    overflow: hidden;
    margin: 1rem;
    z-index: 2;
}

.imgFluid {
    max-width: 100%;
    height: auto;
}

.profileFrame {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
}

.blob {
    position: absolute;
    z-index: -1;
    width: calc(var(--prof-pic-size) + 100px);
    height: calc(var(--prof-pic-size) + 100px);
}

.blobTeal {
    background: url(../../img/blob__teal.svg) no-repeat center;
    animation: rotate 15s ease-in-out infinite;
}

.blobBlue {
    background: url(../../img/blob__blue.svg) no-repeat center;
    animation: rotate 50s ease-in-out infinite;
}

@keyframes rotate {
    0%{ transform: scale(.95)  rotate(0turn);}
    50% {transform: scale(1.05) rotate(.5turn);}
    100% { transform: scale(.95) rotate(1turn);}
}