.App {
  /* text-align: center; */
  --teal: #9EF0F0;
  --teal-light: #e8f0f0;
  --teal-desat: #bbe6e6;
  --blue: #BAE6FF;
  --emphasize: rgb(51, 83, 141);
  
  
}

.App {
  display: flex;
  flex-flow: column nowrap;
  height: 100vh;
}

.App .main-frame{
  background-image: url('../img/waves.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position-y: bottom;
  background-position-x: center;
  background-attachment: fixed;
  flex-grow: 1;
}

.App .navigator {
  background-color: var(--teal-light);
  border-radius: 3px;
  padding: .3rem .1rem;
  position: -webkit-sticky;
  position: sticky;
  top: 2rem;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.exp-title {
  color: var(--emphasize);
}