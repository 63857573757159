.ProjectCard {
    margin: .6rem 0;
    border-radius: .5rem;
    overflow: hidden;
    padding: 0;
    border: rgb(211, 211, 211) solid 1px;
    
    background-color: rgba(255, 255, 255, .8);
}

/* if backdrop support: more transparent and blurred */
@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    .ProjectCard {
        background-color: rgba(255, 255, 255, .1);
        -webkit-backdrop-filter: saturate(50%) blur(1.2em);
        backdrop-filter: saturate(50%) blur(1.2em);
    }
}
.ProjectCard h5 {
    color: var(--emphasize);
    font-weight: bold;
}

.ToolTags {
    display: flex;
    flex-flow: row-reverse;
}

.ToolTags div {
    padding: 0 .4rem;
    margin: .1rem .3rem;
    border-radius: 3px;
    background-color: rgb(236, 236, 236);
    font-weight: lighter;
    color: rgb(172, 172, 172);
    box-shadow: 2px 2px .1rem #99999933;
}

.TextDescription {
    font-weight: lighter;
}